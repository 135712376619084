<template>
  <div>
    <Navbar page="Pessoas na empresa" link="/cadastros" nameLink="Cadastros" video="https://youtu.be/ZA7dytkGVGA"/>
    <div class="mx-4 my-4 md:mx-8 md:my-8">

      <h1 class="text-3xl mb-5 pt-3">Pessoas</h1>

      <div class="bg-gray-200 text-sm font-bold w-72 flex flex-col mb-4 rounded-md p-2">
        <span>Pessoas ativas: {{totalPessoasAtivas}}</span>
        <span>Pessoas desligadas: {{totalPessoasDesligadas}}</span>
      </div>


      <!-- <div class="flex flex-row space-x-5">
        <div class="mb-5 border py-2 px-3 md:w-128">
          <p class="text-base font-semibold">URL Login com White Label: </p>
          <a class="text-sm underline cursor-pointer" target="_blank" :href="`http://${url}/login/${$store.state.empresa.hash}`">{{ `${url}/login/${$store.state.empresa.hash}` }}</a>
          <p class="text-sm text-red-700 font-semibold">O endereço acima é o endereço (url) que deverá ser informado para as pessoas na empresa. É com este endereço que elas acessarão a Fácil Gestão LGPD. </p>
        </div>

        <div class="shadow-md h-12 p-4">
          {{ total }} pessoas
        </div>
      </div> -->
        
      <div class="flex flex-wrap gap-4 mb-3 justify-start text-center">
        
      
        <router-link :to="`/cadastros/${route}/form`" type="button" 
          :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
          class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 
          focus:bg-yellow-700 focus:shadow-sm focus:ring-4 
          focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 px-4
          rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center w-full md:w-auto
          ">
            Novo
        </router-link>
        
        <a :href="`${url_api}/v1/${route}/exportar/${$store.state.empresa._id}?token=${$store.state.token}`" target= "_blank" rel="noreferrer" class="bg-blue-400 hover:bg-blue-500 focus:bg-blue-500 focus:shadow-sm focus:ring-4 text-white flex py-2 px-4 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold justify-center w-full md:w-auto">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
            </svg>
            Download base 
        </a>
       
        <button @click="$modal.show('importarModal')" class="bg-green-400 hover:bg-green-500 focus:bg-green-500 focus:shadow-sm focus:ring-4 text-white flex py-2 px-4 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold justify-center w-full md:w-auto">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
          </svg> 
          Importar base 
        </button>

        <button
          v-if="!arquivados"
          type="button"
          @click="abrirModalTrocarTransferir"
          class="text-white font-semibold text-sm hover:bg-purple-600 bg-purple-500 rounded-lg py-2 px-4 w-full md:w-auto">
            Transferir ou trocar atividades entre pesssoas
        </button>
      
        <!-- <button 
          @click="excluirSelecionadas" 
          v-if="selecionados.length && !arquivados" 
          type="button" 
          class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center w-full md:w-auto">
          Desligar selecionadas
        </button> -->
        <button 
          @click="excluirDefinitivamenteSelecionadas" 
          v-if="selecionados.length && !arquivados" 
          type="button" 
          class="transition duration-200 bg-red-700 hover:bg-red-600 text-white py-2 px-4 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center w-full md:w-auto">
          Excluir definitivamente os selecionados
        </button> 
        
      </div>
      <!-- <div class="col-span-12 2xl:col-span-4 flex py-2">
        <input 
          type="checkbox" 
          v-model="arquivados" 
          @change="updateFiltro" 
          class="rounded-sm" 
          id="visualizarDesligados"
        />
        <label for="visualizarDesligados" class="block text-sm font-medium ml-2">
            Visualizar desligados
        </label>
      </div> -->

      <div class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 xl:col-span-10 mb-1 mt-1">
            <input v-model="busca" type="text" name="busca" id="busca" placeholder="Busque pelo nome" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <button @click="updateFiltro" class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </div>
      </div>

      <modal name="novoItem" :adaptive="true" :height="'auto'" :clickToClose="false"> 
        <div class="px-5 py-5"> 
            <h2 class="text-2xl font-bold mb-4">
              Trocar responsável 
            </h2>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 flex items-center gap-1">
                <label class="block text-sm">
                  Você está prestes a desligar
                </label>
                <label class="block text-xl font-bold">
                  {{ responsavelParaArquivar ? responsavelParaArquivar.nome : '' }}
                </label>
              </div> 
              <div class="col-span-12">
                Antes de desligar adicione um responsável para transferir todas as atividades do mapeamento. 
              </div>
                <div class="col-span-12">
                    <label for="setor" class="block text-sm font-bold">
                      Transferir atividades para:
                    </label>
                    <select 
                      required 
                      v-model="responsavelSubstituto" 
                      name="responsavelSubstituto" id="responsavelSubstituto" 
                      class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                      <option selected :value="null">Selecione responsável para transferir</option>  
                      <option 
                          v-for="responsavel in responsaveisPossiveis" 
                          :key="responsavel._id" 
                          :value="responsavel._id"
                          >
                          {{ responsavel.nome }}
                        </option>
                    </select>
                </div> 
            </div>
            <div class="py-3 flex justify-between">
                <button @click="limparResponsavelSubstituto('novoItem')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 ">
                    Voltar
                </button>
                <button @click="trocarResponsavelExcluir" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-800 ">
                    Transferir e confirmar desligamento
                </button>
            </div>
          </div>
        </modal>

        <modal name="trocarAtividadesPessoas" :adaptive="true" :height="'auto'" :clickToClose="false"> 
        <div class="px-5 py-5"> 
            <h2 class="text-2xl font-bold mb-4">
              Transferir ou trocar atividades 
            </h2>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 flex items-center gap-1">
                <input 
                  type="radio" 
                  name="opcao_troca_transferir"
                  v-model="opcao_troca_transferir"
                  value="transferir"
                  class="rounded-sm" 
                  id="transferirAtividades"
                />
                <label for="transferirAtividades" class="block text-sm font-medium ml-2">
                    Transferir atividades de uma pessoa para a outra.
                </label>
              </div> 
              <div class="col-span-12 flex items-center gap-1">
                <input 
                  type="radio" 
                  name="opcao_troca_transferir"
                  v-model="opcao_troca_transferir"
                  value="trocar"
                  class="rounded-sm" 
                  id="trocarAtividades"
                />
                <label for="trocarAtividades" class="block text-sm font-medium ml-2">
                    Trocar atividades entre duas pessoas.
                </label>
              </div> 
  
                <div class="col-span-12">
                    <label for="setor" class="block text-sm font-bold">
                      {{ opcao_troca_transferir === 'transferir' ? 'De':'' }}
                    </label>
                    <select 
                      @change="responsaveisPossiveisTrocarTransferir"
                      required 
                      v-model="responsaveis.primeiro" 
                      name="responsaveisPrimeiro" id="responsaveisPrimeiro" 
                      class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                      <option selected :value="null">Selecione responsável</option>  
                      <option 
                          v-for="responsavel in responsaveisPossiveisPrimeiro" 
                          :key="responsavel._id" 
                          :value="responsavel._id"
                          >
                          {{ responsavel.nome }}
                        </option>
                    </select>
                </div> 

                <div class="col-span-12">
                    <label for="setor" class="block text-sm font-bold">
                      {{ opcao_troca_transferir === 'transferir' ? 'Para':'' }}
                    </label>
                    <select 
                      @change="responsaveisPossiveisTrocarTransferir"
                      required 
                      v-model="responsaveis.segundo" 
                      name="responsaveisSegundo" id="responsaveisSegundo" 
                      class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                      <option selected :value="null">Selecione responsável</option>  
                      <option 
                          v-for="responsavel in responsaveisPossiveisSegundo" 
                          :key="responsavel._id" 
                          :value="responsavel._id"
                          >
                          {{ responsavel.nome }}
                        </option>
                    </select>
                </div> 
            </div>
            <div class="py-3 flex justify-between">
                <button @click="limparResponsavelSubstituto('trocarAtividadesPessoas')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 ">
                    Voltar
                </button>
                <button @click="transferirTrocar()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-800 ">
                  {{ opcao_troca_transferir === 'transferir' ? 'Transferir':'Trocar' }}
                </button>
            </div>
          </div>
        </modal>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr class="font-bold text-gray-800 text-xs">
                    <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                      <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#000000" viewBox="0 0 256 256"><path d="M220,48V208a12,12,0,0,1-12,12H136a4,4,0,0,1,0-8h72a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4v96a4,4,0,0,1-8,0V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48ZM117.17,157.17,64,210.34,42.83,189.17a4,4,0,0,0-5.66,5.66l24,24a4,4,0,0,0,5.66,0l56-56a4,4,0,0,0-5.66-5.66Z"></path></svg>
                    </th>
                    <th scope="col" class="px-6 py-3 tracking-wider">
                      Nome
                    </th>
                    <th scope="col" class="px-6 py-3 tracking-wider">
                      Função
                    </th>
                    <th scope="col" class="px-6 py-3 tracking-wider">
                      Setor
                    </th>
                    <th scope="col" class="px-6 py-3 tracking-wider">
                      Organização
                    </th>
                    <th scope="col" class="px-6 py-3 tracking-wider">
                      Situação
                    </th>
                    <th scope="col" class="px-6 py-3 tracking-wider">
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id" class="text-xs text-center">
                    <td class="px-3 py-4 whitespace-nowrap" v-if="!arquivados">
                      <div class="flex items-center">
                        <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                      </div>
                    </td>
                    <td>
                        {{item.nome ? item.nome : ''}}
                    </td>

                    <td>
                        {{item.funcao ? item.funcao.nome : ''}}
                    </td>

                    <td>
                        {{item.setor ? item.setor.nome : ''}}
                    </td>
                   
                    <td>
                      {{item.organizacao ? item.organizacao.nome : ''}}
                    </td>
                   
                    
                    <td class=" text-center w-36">
                      <span class="text-gray-500 flex flex-col">
                        <span>
                          {{ item.ativo ? 'Ativo' : item.dataDesligamento ? 'Desligado em' : 'Desligado' }}
                        </span>
                        <span>
                          {{ item.dataDesligamento | moment("DD/MM/YYYY") }}
                        </span>
                      </span>
                    </td>


                    <td class="p-2 font-medium flex flex-wrap gap-1 mx-auto text-white w-72">
                      
                      <button
                        v-if="!arquivados"
                        @click="gerarLogin(item._id)" 
                        class=" hover:bg-green-500 bg-green-700 rounded-full py-1 px-4 w-full md:w-auto">
                          Login
                      </button>
                  
                      <router-link 
                      v-if="!arquivados"
                      :to="`/cadastros/${route}/form/${item._id}`"
                      class="hover:bg-blue-600 bg-blue-500 rounded-full py-1 px-4 w-full md:w-auto">
                      Editar
                      </router-link>

                      <button
                      v-if="item.ativo"
                      @click="remove(item._id)" 
                      href="#" 
                      class="hover:bg-red-500 bg-red-600 rounded-full py-1 px-4 w-full md:w-auto">
                      Desligar
                      </button>

                     

                      <button
                      v-if="!item.ativo"
                      @click="ativar(item._id)" 
                      href="#" 
                      class="hover:bg-green-700 bg-green-500 rounded-full py-1 px-4 w-full md:w-auto">
                      Religar
                      </button>


                      <button
                      @click="excluirDefinitivamente(item._id)" 
                      href="#" 
                      class="hover:bg-red-600 bg-red-800 rounded-full py-1 px-4 w-full md:w-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>
                      </button>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
      <modal name="linkLogin" :adaptive="true" :height="'auto'">
        <div class="px-5 py-5">
          <h2 class="text-xl font-bold mb-4">LINK Login Cliente</h2>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-8 sm:col-span-10">
              <input type="text" id="linklogin" disabled :value="link" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="col-span-4 sm:col-span-2">
              <button @click="copyClipboard" class="text-white hover:bg-blue-500 bg-blue-700 rounded py-2 px-4 w-full">
                Copiar
              </button>
            </div>
          </div>
        </div>
      </modal>
      <modal name="importarModal" :adaptive="true" :height="'auto'">
        <div class="px-5 py-5">
          <h2 class="text-xl font-bold mb-2"> Importar base</h2>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
                <a target= "_blank" rel="noreferrer" :href="`/arquivo-modelo-5.csv`" class="text-sm underline">Exemplo arquivo modelo importação</a>
                <small class="block text-sm mt-3 text-red-800">Para <b>novos</b> cadastro a senha default é: <b>mudar123</b></small>
            </div>
            <div class="col-span-12">
              <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="update">
              </vue-dropzone>
            </div>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from 'moment';
export default {
  components: {
    vueDropzone
  },
  data() {
    return {
      route: 'pessoas',
      selecionados: [],
      list: [],
      listAllAtivosOnly: [],
      responsaveisPossiveis: [],
      responsavelSubstituto: null,
      responsavelParaArquivar: null,
      page: 1,
      perPage: 10,
      busca: '',
      total: 0,
      totalPessoasDesligadas: 0,
      totalPessoasAtivas: 0,
      arquivados: false,
      opcao_troca_transferir: 'transferir',
      responsaveis: {
        primeiro: null,
        segundo: null
      },
      responsaveisPossiveisPrimeiro: [],
      responsaveisPossiveisSegundo: [],
      query: { page: 1, limit: 10, skip: 0  },
      link: '',
      dropzoneOptions: this.$http.getDropzoneConfig(
        `v1/pessoas/importar-csv`,
        'post',
      {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
        dictRemoveFile: 'Remover'
      }),
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { 
        arquivados: this.arquivados,
        busca: this.busca, 
        skip: this.query.skip, 
        limit: this.query.limit,
        listarAtivosEArquivados: true,
      });

      this.list = listReq.data.data;
      this.total = listReq.data.total;

      this.totalPessoasDesligadas = listReq.data.totalPessoasDesligadas;
      this.totalPessoasAtivas = listReq.data.totalPessoasAtivas;



      //TODOS OS ATIVOS PARA USAR EM TRANFERENCIAS
      const listAllAtivosReq = await this.$http.post(`/v1/${this.route}/list`, { 
        arquivados: this.arquivados,
        busca: '', 
        skip: 0, 
        limit: 999999,
        all: true,
      });

      this.listAllAtivosOnly = listAllAtivosReq.data.data
    },
    async excluirDefinitivamenteSelecionadas(){

      for(let i=0;i<this.selecionados.length;i++){
        const id = this.selecionados[i]._id
        const nomePessoa = this.selecionados[i].nome

        const atividades = await this.pegarAtividades(id)
        if(atividades.length>0){
          this.responsavelParaArquivar = this.list.find((item)=> item._id === id) || null
          this.responsaveisPossiveis = this.listAllAtivosOnly.filter((item)=> item._id !== id) || []

          return this.$vToastify.warning(`Transfira as atividades de ${nomePessoa} antes de excluir definitivamente.`);
        }
      }
      
      this.$confirm({
        title: 'Excluir definitivamente os selecionados',
        message: `Deseja excluir definitivamente as pessoas selecionadas ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          
          await this.$http.post(`/v1/${this.route}/selecionados/excluir-definitivamente`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Excluido definitivamente com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },
    async excluirSelecionadas(){
      this.$confirm({
        title: 'Desligar selecionados',
        message: `Deseja desligar pessoas selecionadas ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Desligados com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },
    limparResponsavelSubstituto(modal){
      this.responsavelSubstituto = null
      this.$modal.hide(modal)
    },
    async trocarResponsavelExcluir(){
      if(this.responsavelSubstituto){

        this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja transferir atividades e desligar ${this.responsavelParaArquivar.nome}?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.post(`/v1/pessoas/transferir-excluir`, { 
                responsavelSubstituto: this.responsavelSubstituto, 
                responsavelParaExcluir: this.responsavelParaArquivar._id,
              }).then((res)=>{
                this.$vToastify.success(res.data.msg);
                this.page = 1;
                this.start();
              }).catch((e)=>{
                this.$vToastify.error(e.response.data.err || "Erro ao transferir e desligar pessoa.");
              })
            }
            this.limparResponsavelSubstituto('novoItem')
          }
        });
      }else{
        return this.$vToastify.warning("Selecione um responsável para transferir atividades!");
      }
    },

    async transferirTrocar(){
      const opcao = this.opcao_troca_transferir
      if(!opcao){
        return this.$vToastify.warning("Selecione trocar ou transferir atividades.");
      }
      if(!this.responsaveis.primeiro || !this.responsaveis.segundo){
        return this.$vToastify.warning(`Selecione responsáveis para ${opcao} atividades.`);
      }

      this.$confirm({
        title: 'Confirmação',
        message: `Tem certeza que deseja ${opcao} atividades ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if (confirm) {

            if(opcao === "transferir"){
              await this.$http.post(`/v1/pessoas/transferir`, { 
                responsavelSubstituto: this.responsaveis.segundo, 
                responsavelAtual: this.responsaveis.primeiro,
              }).then((res)=>{
                this.$vToastify.success(res.data.msg);
                this.page = 1;
                this.start();
              }).catch((e)=>{
                this.$vToastify.error(e.response.data.err || "Erro ao transferir atividades.");
              })
            }else if(opcao === "trocar"){
              await this.$http.post(`/v1/pessoas/trocar`, { 
                primeiro_resp: this.responsaveis.primeiro, 
                segundo_resp: this.responsaveis.segundo,
              }).then((res)=>{
                this.$vToastify.success(res.data.msg);
                this.page = 1;
                this.start();
              }).catch((e)=>{
                this.$vToastify.error(e.response.data.err || "Erro ao trocar atividades.");
              })
            }
            
          }
          this.limparResponsavelSubstituto("trocarAtividadesPessoas")
        }
      });
    },
    async pegarAtividades(id){

      const filtro = {
        desativadas: false,
      }
      if(id) filtro.responsavel = id;

      const ReqAtividades = await this.$http.post(`/v1/atividades/list`, filtro)
      const atividades = ReqAtividades?.data?.data || []

      return atividades;     
    },
    async abrirModalTrocarTransferir(){

      this.responsaveis = {
        primeiro: null,
        segundo: null,
      }

      this.responsaveisPossiveisTrocarTransferir()
      this.$modal.show('trocarAtividadesPessoas');

    },

    responsaveisPossiveisTrocarTransferir(){
      const idPrimeiro = this.responsaveis.primeiro
      const idSegundo = this.responsaveis.segundo

      if(idPrimeiro){
        this.responsaveisPossiveisSegundo = this.listAllAtivosOnly.filter((item)=> item._id !== idPrimeiro) || []
      }else{
        this.responsaveisPossiveisSegundo = this.listAllAtivosOnly || []
      }

      if(idSegundo){
        this.responsaveisPossiveisPrimeiro = this.listAllAtivosOnly.filter((item)=> item._id !== idSegundo) || []
      }else{
        this.responsaveisPossiveisPrimeiro = this.listAllAtivosOnly || []
      }
    
    },
    async excluirDefinitivamente(id){
      const atividades = await this.pegarAtividades(id)
      if(atividades.length>0){
        this.responsavelParaArquivar = this.list.find((item)=> item._id === id) || null
        this.responsaveisPossiveis = this.listAllAtivosOnly.filter((item)=> item._id !== id) || []

        return this.$vToastify.warning("Transfira as atividades da pessoa antes de excluir definitivamente");
      }

      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja excluir definitivamente essa pessoa?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              console.log('vamos excluir', id)
              await this.$http.delete(`/v1/${this.route}/excluir-definitivamente/${id}`);
              this.$vToastify.success("Excluido definitivamente com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },

    async remove(id) {
      const atividades = await this.pegarAtividades(id)
      if(atividades.length>0){
        this.responsavelParaArquivar = this.list.find((item)=> item._id === id) || null
        this.responsaveisPossiveis = this.listAllAtivosOnly.filter((item)=> item._id !== id) || []
        this.$modal.show('novoItem');
        return;
      }

      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja arquivar essa pessoa?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/${this.route}/${id}`);
              this.$vToastify.success("Removido com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },
    async ativar(id) {
      this.$confirm({
        title: 'Confirmação',
        message: `Tem certeza que deseja ativar essa pessoa?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if (confirm) {
            await this.$http.put(`/v1/pessoas/`, { _id: id, ativo: true })
            .then(()=> {
              this.$vToastify.success('Pessoa ativada com sucesso');
              this.page = 1;
              this.start();
            })
            .catch(()=> this.$vToastify.error('Erro ao ativar pessoa.'))
          }
        }
      });
    },

    update(file, response) {
      if(response.success) this.$vToastify.success("Importando! Quando finalizado você será alertado no email.");
      else this.$vToastify.error(response.err);
      this.$modal.hide('importarModal');
      this.start();
    },

    send(file, xhr, formData) {
      formData.append('empresa', this.$store.state.empresa && this.$store.state.empresa._id ? this.$store.state.empresa._id : null);
    },

    async gerarLogin(id) {
      const req = await this.$http.get(`/v1/${this.route}/link-login/${id}`);
      if(!req.data.link) return this.$vToastify.error("Erro ao gerar login!");
      this.link = req.data.link;
      this.$modal.show('linkLogin');
    },

    copyClipboard () {
        navigator.clipboard.writeText(this.link);
        this.$vToastify.success("Copiado com sucesso!");
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { 
        page: this.query.page, 
        limit: this.query.limit, 
        skip: this.query.skip, 
        busca: this.busca,
        arquivados: this.arquivados,
        t: moment().format('x'),
      };
      this.$router.push({ path: `/cadastros/${this.route}`, query: this.query });
    },

    async selecionarTodos(){
      if (!this.selecionados.length) {
        const listReq = await this.$http.post(`/v1/${this.route}/list`, { 
          all: true, 
          busca: this.busca, 
          listarAtivosEArquivados: true  
        });
        this.selecionados = listReq.data.data;
      }else {
        this.selecionados = [];
      }
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.busca = this.busca;
      this.query.arquivados = this.arquivados;

      this.$router.push({ path: `/cadastros/${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.arquivados) this.arquivados = this.$route.query.arquivados === 'true' ? true : false;
    if(this.$route.query.busca) this.busca = this.$route.query.busca;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.arquivados) this.arquivados = this.$route.query.arquivados === 'true' ? true : false;
    if(this.$route.query.busca) this.busca = this.$route.query.busca;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>